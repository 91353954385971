<template>
  <div class="store_search">
    <div class="search_header">
      <Search
        v-model.trim="searchKey"
        shape="round"
        show-action
        placeholder="搜索店内药品"
        @search="toSearch">
        <template #left-icon>
          <div class="left_icon">
            <img
              :src="require('@img/icon_search_grey.png')"
              alt="">
          </div>
        </template>
        <template #action>
          <div
            :class="{ btn_active: searchKey.length }"
            class="btn"
            @click="toSearch">
            搜索
          </div>
        </template>
      </Search>
    </div>
    <!--
        <div v-if="list.length">
            <List
                  :class="adjust_height"
                v-model="loading"
                :finished="finished"
                @load="onLoad"
                finished-text="到底了">
                <div class="list_wrapper pt12 pl12 pr12">
                    <div
                        v-for="item in list"
                        :key="item.goodsId"
                        @click="toDrugDetail(item)"
                        class="card pl12 pr12">
                        <Drug-item :data="mapItemToDrugItem(item)" />
                    </div>
                </div>
            </List>
        </div> -->

    <!-- :class="adjust_height" -->
    <div
      v-if="list.length"
      class="list">
      <List
        v-model="loading"
        :finished="finished"
        finished-text="到底啦～"
        load="onLoad">
        <DrugItem
          v-for="drugData in list"
          :key="drugData.goodsId"
          :data="drugData"
          @click="goDrugDetail(drugData)">
          <!-- <template v-slot:numOrCount>
            <div class="add">
              <Counter :attr="mapDataToAttr(drugData)" />
            </div>
          </template> -->
        </DrugItem>
      </List>
    </div>
    <!-- <Shopping-cart
      v-if="this.$route.query.merchantCode"
      :merchant-code="this.$route.query.merchantCode"
    /> -->
    <div
      v-if="!list.length"
      class="empty">
      <Empty-data tip="未找到搜索结果" />
    </div>
  </div>
</template>

<script>
import { List, Search } from 'vant';
import DrugItem from '@/components/order/drug-item';
import EmptyData from '@/components/empty-data';
import ShoppingCart from '@/components/shopping-cart';
import Counter from '@/components/business/add-subtract';
import api from '@/api';
import { mapGetters, mapState } from 'vuex';
import Configs from '@/config';

export default {
  components: {
    List,
    Search,
    DrugItem,
    EmptyData,
    ShoppingCart,
    Counter,
  },
  data() {
    return {
      list: [],
      searchKey: '',
      loading: false,
      finished: false,
      pageSize: 10,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      shoppingList: (store) => store.shoppingCart.list,
    }),
    ...mapGetters('shoppingCart', [
      'mapDataToAttr',
    ]),
  },
  created() {
    console.log(this.$route.query, '-------0000');
    document.title = this.$route.query.merchantName;
    this.searchKey = this.$route.query.search || '';
    this.init();
  },
  methods: {
    init() {
      const param = {
        drugstoreCode: this.$route.query.merchantCode,
        keyword: this.searchKey || '片',
        // pageNumber: this.pageNumber,
        // pageSize: this.pageSize,
      };
      const channelCode = localStorage.getItem('channelCode');
      if (channelCode === 'PAK') { // 派安康来源
        param.saleChannel = Configs.channelCode[channelCode.toUpperCase()];
      }
      api.getSHYStoreInfo(param)
        .then((res) => {
          // const { list = [], totalPage = 0 } = res.data;
          // this.list = this.list.concat(list);
          // this.loading = false;
          // this.totalPage = totalPage;
          // if (this.page >= this.totalPage) {
          //   this.finished = true;
          // }

          const { goodsInfo, pharmacyInfo } = res.data;
          // this.goodsList = this.goodsList.concat(list);
          this.goodsList = goodsInfo || [];
          this.list = this.list.concat(this.goodsList);
          this.totalPage = 1;
          this.loading = false;
          if (this.page >= this.totalPage) {
            this.finished = true;
          }
        });
    },
    onLoad() {
      if (this.totalPage && this.page < this.totalPage) {
        this.page += 1;
        this.getList();
      }
    },
    toSearch() {
      if (!this.searchKey.length) return;
      this.pageNumber = 1;
      this.list = [];
      this.init();
    },
    goDrugDetail(item) {
      this.$router.push({
        name: 'drug-shy',
        params: {
          id: item.goodsId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.store_search {
  background: #fff;
  height: 90vh;
  .search_header {
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
    // flex: 1;
    .left_icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .van-cell {
      padding: 0;
      line-height: 28px;
      font-size: 14px;
    }

    .van-search {
      padding: 5px 18px;
    }
    /deep/ .van-field__control {
      // font-size: 14px;
      font-weight: 400;
      // color: #babfc9;
    }
    /deep/ .van-field__left-icon {
      display: flex;
    }
    .btn {
      color: #cfcfd2;
      // font-size: 14px;
      // font-weight: 500;
      // color: #cfcfd2;

      // &.active {
      //     color: #3679f0;
      // }
    }
    .btn_active {
      color: #3679f0;
    }
  }
  .list {
    padding: 4px 18px 0;
  }
  .empty {
    background: #f5f7fa;
    height: 100vh;
  }
  /deep/ .van-list__finished-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    width: auto;
    font-size: 11px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #babfc9;
    line-height: 16px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      margin: 0 8px;
      width: 40px;
      height: 1px;
      background: #dcdfe4;
      color: red;
    }
  }
}
</style>
